import Vue from 'vue';
import App from './App.vue';
import router from './router'; // 导入路由配置文件
import store from './store'; // 导入 Vuex store
import ElementUI from 'element-ui'; // 导入 Element UI
import 'element-ui/lib/theme-chalk/index.css'; // 导入 Element UI 样式
import './assets/style/theme/index.css'; // 导入主题样式
import axios from 'axios'; // 导入 Axios
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

Vue.use(ElementUI); // 使用 Element UI
Vue.prototype.$axios = axios; // 将 Axios 挂载到 Vue 实例的原型上，以便全局使用
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);

new Vue({
  router, // 使用路由配置
  store, // 使用 Vuex store
  render: (h) => h(App),
}).$mount('#app');