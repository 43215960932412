<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style lang="less">
  body {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif, '-apple-system', 'BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    line-height: 2;
    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    img {
      margin: 0;
      padding: 0;
    }
  }
  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    background: #ffffff;
    .index {
      position: relative;
      .el-menu {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 10;
      }
    }
  }
  .wrap {
    padding: 0 calc((100% - 1100px)/2) !important;
  }
</style>
