import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // 路由配置
  {
    path: '/index',
    redirect: '/'
  },
  {
    path:'/',
    name: 'Index',
    component: () => 
        import('../views/Index.vue'),
    
  },
  {
    path:'/search/:id?',
    name: 'Search',
    component: () => 
        import('../views/Search.vue'),
    
  },
  {
    path:'/company/:id',
    name: 'Company',
    component: () => 
        import('../views/Company.vue'),
    
  },
  {
    path:'/article/:id',
    name: 'Article',
    component: () => 
        import('../views/Article.vue'),
    
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  // 其他配置
});

export default router;